import classes from "./Vertical.module.css";
import Title from "../../UI/box/title/Title";
import Entity from "../../UI/box/entity/Entity";
import { useEffect, useState, useRef } from "react";
import { useStore } from "./../../../hooks/store";
import parse from "html-react-parser";
import Slider from "./../../slider/CustomSlider";
import { SRLWrapper } from "simple-react-lightbox";
import SimpleReactLightbox from "simple-react-lightbox";
import { Fragment } from "react";
import RadarChart from "../chartjs/RadarChart";
import { useLocation } from "react-router-dom";

const options = {
  settings: {
    overlayColor: "rgb(104, 104, 104)",
    autoplaySpeed: 1500,
    transitionSpeed: 900,
  },
  buttons: {
    backgroundColor: "black",
    iconColor: "white",
  },
  caption: {
    captionColor: "white",
    captionFontFamily: "Raleway, sans-serif",
    captionFontWeight: "300",
    captionTextTransform: "uppercase",
  },
  progressBar: {
    backgroundColor: "#f2f2f2",
    fillColor: "#000000",
    height: "3px",
    showProgressBar: true,
  },
};

const Vertical = () => {
  const [data, setData] = useState({ metrics: [], title: "" });
  const [loadingData, setLoadingData] = useState(false);

  const state = useStore()[0];
  const typeStyleVertical = useRef(null);
  const colorIconTitle = useRef(null);
  const iconTitleRef = useRef(null);
  const { search } = useLocation();

  useEffect(() => {
    if (search !== "") {
      const currTypeURL = new URLSearchParams(search).get("typeId");
      const currSensorURL = new URLSearchParams(search).get("sensorId");

      const tempTypeIdActive = state.types.filter(
        (item) => item.id === currTypeURL
      );

      if (tempTypeIdActive.length > 0 && state.sensors.length > 0) {
        iconTitleRef.current = tempTypeIdActive[0].icon;
        colorIconTitle.current = {
          backgroundColor: tempTypeIdActive[0].color,
          minWidth: "50px",
          maxWidth: "50px",
          minHeight: "50px",
          maxHeight: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "12px",
        };

        setLoadingData(true);

        if (currSensorURL) {
          const sensor = state.sensors.filter(
            (item) => item.id.toString() === currSensorURL
          );

          if (sensor.length > 0) {
            if (sensor[0].typeId.toString() === "7") {
              setData({
                historic: {
                  category: sensor[0].categoryName,
                  image: sensor[0].thumbnail,
                  content: sensor[0].content,
                  images: sensor[0].images,
                  // images: [
                  //   "https://alexandroupoli.repository.gr/files/asset/b2d3f6e7dc5dd2435bdb3f21127ecf60221e71b8.jpg",
                  //   state.sensors[i].thumbnail,
                  // ],
                },
                title: sensor[0].title,
              });
              typeStyleVertical.current = "POIS";
            } else {
              console.log(
                sensor[0].metrics,
                sensor[0].metrics.includes("Συντεταγμένες κάδου")
              );
              let place = sensor[0].metrics.filter(
                (item) => item.content === "Συντεταγμένες κάδου"
              )[0];

              if (place) {
                const cords = place.value.split(" ");
                console.log(cords);
                fetch(
                  `https://nominatim.openstreetmap.org/reverse?format=json&lat=${cords[0]}&lon=${cords[1]}&accept-language=el`
                )
                  .then((response) => response.json())
                  .then((data) => {
                    console.log(data);

                    const dataMetrics = sensor[0].metrics.filter(
                      (item) =>
                        item.content !== "Συντεταγμένες κάδου" &&
                        item.content !== "Τάση μπαταρίας"
                    );
                    const tempLocation = {
                      content: "Περιοχή κάδου",
                      value: data.address.village
                        ? data.address.village
                        : data.address.town,
                    };
                    dataMetrics.push(tempLocation);
                    dataMetrics.push({
                      content: "Τύπος κάδου",
                      value: "Μπλε ανακύκλωσης 1100 lt",
                    });

                    setData({
                      metrics: dataMetrics,
                      title: sensor[0].title,
                    });
                    typeStyleVertical.current = "Metrics";
                  })
                  .catch((error) => console.warn(error));
              } else {
                setData({
                  metrics: sensor[0].metrics,
                  title: sensor[0].title,
                });
                typeStyleVertical.current = "Metrics";
              }
            }
          }
        } else {
          setData({ metrics: [], title: "" });
        }
      }
    }
  }, [search, state.types, state.sensors]);

  useEffect(() => {
    if (data.title !== "") {
      setLoadingData(false);
    }
  }, [data]);

  const Metrics =
    data.metrics &&
    data.metrics
      .filter((item) => item.value)
      .map((item, index) => (
        <Entity
          key={index}
          icon={item.icon_large}
          content={item.content}
          value={item.value}
          className={classes.gradient_border}
          styleValue={{ fontWeight: "400" }}
        />
      ));

  const POIS = data.historic && (
    <Fragment>
      <SimpleReactLightbox>
        <SRLWrapper options={options}>
          {data.historic.image && (
            <img
              src={data.historic.image}
              alt="thumbnail"
              width="100%"
              style={{ cursor: "pointer" }}
            />
          )}
        </SRLWrapper>
      </SimpleReactLightbox>

      {data.historic.category && (
        <p style={{ fontSize: "14px" }}>
          Κατηγορία: <i>{data.historic.category}</i>
        </p>
      )}
      {data.historic.content && (
        <div style={{ marginTop: "20px", textAlign: "justify" }}>
          {parse(data.historic.content)}
        </div>
      )}

      {data.historic.images.length > 0 && (
        <div className={classes.slider_placeholder}>
          <p className={classes.more_photos_descr}>
            <i>Επιπλέον φωτογραφικό υλικό:</i>
          </p>
          <SimpleReactLightbox>
            <SRLWrapper options={options}>
              <Slider
                settings={{
                  infinite: false,
                }}
              >
                {data.historic.images.map((image, index) => (
                  <div key={index} className={classes.image_placeholder}>
                    <img
                      src={image}
                      alt={data.title}
                      width="100%"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                ))}
              </Slider>
            </SRLWrapper>
          </SimpleReactLightbox>
        </div>
      )}
    </Fragment>
  );
  return (
    <div className={classes.info_container}>
      <div className={classes.sticky_title}>
        <Title
          icon={iconTitleRef.current}
          title={data.title}
          styleIcon={colorIconTitle.current}
          className={[classes.info_title, classes.height_unset].join(" ")}
        />
        <hr className={classes.gradient_line_style} />
      </div>

      {!loadingData && typeStyleVertical.current === "Metrics" && Metrics}
      {!loadingData && typeStyleVertical.current === "POIS" && POIS}
      {new URLSearchParams(search).get("typeId") === "11" && (
        <RadarChart
          clockColor={
            colorIconTitle.current && colorIconTitle.current.backgroundColor
          }
          sensorId={new URLSearchParams(search).get("sensorId")}
        />
      )}
    </div>
  );
};

export default Vertical;

import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <div className={classes.footer}>
      <p className={classes.copyright}>&copy;2019 - 2021 Dotsoft</p>
    </div>
  );
};

export default Footer;

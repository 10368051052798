import classes from "./Meteo.module.css";
import { Fragment, useEffect, useState, useRef } from "react";
import Title from "../../../UI/box/title/Title";
import SubTitle from "../../../UI/box/subtitle/SubTitle";
import Entity from "./../../../UI/box/entity/Entity";
import CustomSlider from "../../../slider/CustomSlider";

import { useStore } from "./../../../../hooks/store";
import slice_lists from "../../../../services/slice_lists";
import replace_duplicate_label from "../../../../services/replace_duplicate_label";
import removeEntityNoIcon from "../../../../services/removeEntityNoIcon";
import { LoadingDot } from "../../../UI/spinners/LoadingSpinners";

import traffic from "./../../../../assets/testImages/diktis_kikloforiakis_simforisis_big.svg";
import avgSpeed from "./../../../../assets/testImages/mesi_taxitita_astikou_diktiou_big.svg";
import walked from "./../../../../assets/testImages/meridio_xrisis_pezis_metakinisis_big.svg";

const Meteo = (props) => {
  const [data, setData] = useState({ devideData: [], devideLabels: [] });
  const imgTitleRef = useRef(null);
  const titleRef = useRef(null);
  const tooltipRef = useRef(null);
  const state = useStore()[0];

  useEffect(() => {
    if (state.sensors.length > 0) {
      for (let y = 0; y < state.types.length; y++) {
        if (state.types[y].id === props.typeId) {
          imgTitleRef.current = state.types[y].icon;
          tooltipRef.current = state.types[y].tooltip;
          break;
        }
      }

      const sensorsInSameType = state.sensors.filter(
        (item) => item.typeId === props.typeId
      );
      const totalData = [];
      const totalLabels = [];
      if (
        sensorsInSameType !== undefined &&
        sensorsInSameType.length > props.previewIndex &&
        sensorsInSameType[props.previewIndex].metrics.length !== 0
      ) {
        const clearedEmptyEntities = removeEntityNoIcon(
          sensorsInSameType[props.previewIndex].metrics
        );
        titleRef.current = sensorsInSameType[props.previewIndex].boxTitle;

        const currList = slice_lists(
          clearedEmptyEntities,
          props.showSubTitle ? props.maxEntities : props.maxEntities + 1
        );
        const currLabels = replace_duplicate_label(
          currList.length,
          clearedEmptyEntities.title
        );

        totalData.push(...currList);
        totalLabels.push(...currLabels);
      } else if (sensorsInSameType !== undefined && !props.previewIndex) {
        for (let i = 0; i < sensorsInSameType.length; i++) {
          if (sensorsInSameType[i].metrics.length === 0) break;

          const clearedEmptyEntities = removeEntityNoIcon(
            sensorsInSameType[i].metrics
          );

          const currList = slice_lists(
            clearedEmptyEntities,
            props.showSubTitle ? props.maxEntities : props.maxEntities + 1
          );
          console.log(clearedEmptyEntities);
          const currLabels = replace_duplicate_label(
            currList.length,
            sensorsInSameType[i].title
          );

          totalData.push(...currList);
          totalLabels.push(...currLabels);
        }
      }

      setData({ devideData: totalData, devideLabels: totalLabels });

      if (props.typeId === "-111") {
        titleRef.current = "Βιώσιμη Αστική Κινητικότητα";
        setData({
          devideData: [
            [
              {
                content: "Δείκτης κυκλοφοριακής συμφόρησης ανά ημέρα (Live)",
                value: "Φυσιολογικές Ροές",
                icon_large: traffic,
              },
              {
                content: "Μέση ταχύτητα αστικού οδικού δικτύου",
                value: "28 χλμ/ώρα",
                icon_large: avgSpeed,
              },
              {
                content: "Μερίδιο χρήσης πεζή μετακίνησης (%)",
                value: "19 %",
                icon_large: walked,
              },
            ],
          ],
          devideLabels: [],
        });
      }
    }
  }, [state.sensors]);

  return (
    <Fragment>
      <Title
        icon={imgTitleRef.current}
        title={props.title}
        link={`/map?typeId=${props.typeId}`}
        infoContext={tooltipRef.current}
        element={
          <i
            id="draggable"
            className="im im-icon-Double-Tap"
            style={{ fontSize: "32px", minWidth: "32px", cursor: "pointer" }}
          />
        }
        id={props.typeId}
      />
      <p
        style={{
          fontSize: "14px",
          textAlign: "center",
          paddingTop: "5px",
          ...props.subTitleColor,
        }}
      >
        <strong>{titleRef.current}</strong>
      </p>

      {data.devideData.length > 0 && (
        <CustomSlider>
          {data.devideData.map((seperateData, index) => (
            <div key={index}>
              {props.showSubTitle && (
                <SubTitle>{data.devideLabels[index]}</SubTitle>
              )}
              {seperateData.map((data, index) => (
                <>
                  {props.typeId !== "-111" && (
                    <Entity
                      key={index}
                      icon={data.icon_large}
                      content={data.content}
                      value={data.value}
                    />
                  )}
                  {props.typeId === "-111" && (
                    <div
                      key={index}
                      style={{ fontSize: "14px", lineHeight: "17px" }}
                    >
                      <p>{data.content}</p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "10px 0 5px",
                        }}
                      >
                        {data.icon_large && (
                          <img src={data.icon_large} alt="icon" width="32px" />
                        )}

                        <p>
                          <strong>{data.value}</strong>
                        </p>
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
          ))}
        </CustomSlider>
      )}
      {data.devideData.length === 0 && <LoadingDot />}
    </Fragment>
  );
};

export default Meteo;

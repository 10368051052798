import classes from "./WelcomeMessage.module.css";

const WelcomeMessage = (props) => {
  return (
    <div className={classes.welcome_placeholder}>
      <p>
        Ο Δήμος {props.municipality} σας καλωσορίζει στην Πλατφόρμα Εξυπνης
        Πόλης.
      </p>
      <p> Ενημερωθείτε για τις συνθήκες που επικρατούν στην πόλη μας.</p>
    </div>
  );
};

export default WelcomeMessage;
